<template>
  <div class="work-details">
    <div v-show="sync">
      <div class="row justify-content-end">
        <div class="col-md-12 text-center" v-if="!blog.title">
          <h2 class="m-4">Sorry the post you are looking for doesn't exist</h2>
          <h4 class="text-white">
            <router-link to="/articles">Back to articles</router-link>
          </h4>
        </div>
        <div class="col-md-10" v-else>
          <div class="work__details_info" data-aos="fade-up">
            <h4 class="work__title">{{ blog.title }}</h4>
            <span>{{ blog.created_at | formatDate }}</span>
            <span class="float-right">{{ readtime }}</span>
            <p></p>
          </div>
          <div class="work__details_text" data-aos="fade-up" data-aos-delay="200">
            <p class="my-4 text-center">
              <img
                v-lazy="`${appConfig.api.articles_image_path}/${blog.image}`"
                :alt="blog.title"
                style="width: 100%;"
              />
              <br />
            </p>
            <div v-highlight>
              <p v-html="blog.content"></p>
            </div>
            <br />
            <p class="my-4">
              <span>
                <i class="ti-user pr-1"></i>
                <span v-if="blog.user">{{ blog.user.name }}</span>
                <span v-else>Admin</span>
              </span>

              <span class="float-right">
                Share:
                <a
                  :href="`http://www.facebook.com/sharer.php?u=${pageurl}`"
                  target="_blank"
                  class="share-facebook mx-2"
                >
                  <i class="ti-facebook"></i>
                </a>
                <a
                  :href="
                    `http://twitter.com/share?text=${blog.title} ${pageurl}`
                  "
                  rel="nofollow"
                  onclick="window.open(this.href,this.title,'width=600,height=600,top=200px,left=200px');  return false;"
                  class="share-twitter mx-2"
                >
                  <i class="ti-twitter"></i>
                </a>
              </span>
            </p>
          </div>

          <next-page path="/articles" title="Articles" content="Go Back To Articles"></next-page>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import Vue from "vue";
import VueCodeHighlight from "vue-code-highlight";

Vue.use(VueCodeHighlight);

export default {
  metaInfo() {
    return {
      title: this.title,
      meta: [
        { name: "description", content: this.blog.title },
        { name: "og:title", content: this.blog.title },
      ],
    };
  },
  computed: {
    readtime: function () {
      let string = this.blog.content.split(" ");
      let len = string.length;
      let time = Math.round(len / 200);

      if (time > 1) {
        return `${time} mins read`;
      } else if (time === 1 || len < 200) {
        return `1 min read`;
      } else {
        return `Please wait`;
      }
    },
    blog() {
      return this.$store.getters.getPost(this.$route.params.slug);
    },
    pageurl() {
      return window.location.href;
    },
    title() {
      let page = this.blog.title ? this.blog.title : "Articles";
      return `${page} - ${this.appConfig.name}`;
    },

    ...mapState(["posts", "sync", "appConfig"]),
  },
};
</script>
